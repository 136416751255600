<template>
  <div>
    <c-search-box @enter="getEquipClassList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :filtering="false"
          :gridHeight="gridClass.height"
          :isFullScreen="false"
          :isExcelDown="false"
          :usePaging="false"
          :expandAll="true"       
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getEquipClassList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemTable"
          title="설비유형별 예방점검항목"
          :columns="insItemgrid.columns"
          :data="insItemgrid.data"
          :merge="insItemgrid.merge"
          selection="multiple"
          :usePaging="false"
          :expandAll="true"
          :gridHeight="insItemgrid.height"
          rowKey="checkItemId"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="부품추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && insItemgrid.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn label="저장" icon="save" @btnClicked="saveClass" v-if="editable && updateMode && insItemgrid.data.length > 0" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemPartName'">
              {{ props.row.checkItemPartName }}
              <span>
                <div v-if="editable && !disabled">
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
        </c-table> 
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">부품명</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-class-ins-item',
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '720px'
      },
      insItemgrid: {
        columns: [],
        data: [],
        height: '720px'
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemId: '',
        checkItemName: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      updateUrl: '',
      checkUrl: '',
      infoInsertUrl: '',
      infoUpdateUrl: '',
      infoDeleteUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      hazardMachineItems: [
        { code: 'Y', codeName: '유해위험기계기구' },
        { code: 'N', codeName: '일반' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave2: false,
      isDelete: false,
      updateMode: false,
      updateMode2: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemId: '',
        checkItemName: '',
      },
      rowTypeName: '',
      rowCheckItemName: '',
      tempInsGridDetail: {
        equipmentTypeCd:  '',
        plantCd:  '',
        equipmentCheckTypeCd:  '',
        checkItemId:  '',
        checkItemName:  '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.deterior.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.deterior.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.deterior.delete.url;
      this.getEquipClassList();

      this.$comm.getComboItems('MDM_METHOD_CHECK_CD').then(_result => {
        this.insItemgrid.columns = [
           {
            required: true,
            name: 'checkItemPartName',
            field: 'checkItemPartName',
            label: '부품',
            btns: [
              { label: '', icon: 'add', color: 'orange', tooltip: '부품별 점검항목 추가' },
            ],
            type: 'custom',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목 내용',
            align: 'left',
            type: 'text',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'checkMethodCd',
            field: 'checkMethodCd',
            required: true,
            label: '검사방법',
            align: 'center',
            style: 'width:110px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            label: '판정기준',
            align: 'left',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            label: '점검시 안전사항',
            align: 'left',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },  
          {
            name: 'cbmTypeCd',
            field: 'cbmTypeCd',
            label: 'CBM<br>종류',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            codeGroupCd: 'CBM_TYPE_CD',
            sortable: false
          },
          {
            name: 'gaugeWarnLcl',
            field: 'gaugeWarnLcl',
            label: '경고<br>하한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeWarnUcl',
            field: 'gaugeWarnUcl',
            label: '경고<br>상한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeExecLcl',
            field: 'gaugeExecLcl',
            label: '실행<br>하한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeExecUcl',
            field: 'gaugeExecUcl',
            label: '실행<br>상한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'cbmFlag',
            field: 'cbmFlag',
            label: 'CBM<br>여부',
            align: 'center',
            style: 'width:50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ]
        this.insItemgrid.merge =  [
          { index: 0, colName: "checkItemPartName" },
        ]
      });
    },
    getEquipClassList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
        this.updateMode = false;
        this.updateMode2 = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeName);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.updateMode = true;
      this.updateMode2 = false;
      // 설비유형별 점검항목 조회
      this.$http.url = this.$format(this.itemDetailUrl, this.key.equipmentTypeCd, this.key.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.insItemgrid.data = this.$_.clone(_result.data);
      },
      () => {
      });
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.prompt = true;
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.insItemgrid.data = this.$_.reject(this.insItemgrid.data, { checkItemId: item.checkItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      if (this.$comm.validTable(this.insItemgrid.columns, this.insItemgrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.insItemgrid.data;
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.rowClick({ plantCd: this.key.plantCd, equipmentTypeCd: this.key.equipmentTypeCd, equipmentTypeName: this.rowTypeName })
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd, equipmentTypeName: this.rowTypeName })
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    innerBtnClicked(col, props) {
      this.insItemgrid.data.splice(props.rowIndex+1, 0, {
        equipmentTypeCd: props.row.equipmentTypeCd,
        plantCd: props.row.plantCd,
        equipmentCheckTypeCd: props.row.equipmentCheckTypeCd,
        checkItemId: uid(),
        checkItemName: props.row.checkItemName,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.insItemgrid.data.push({
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartName: this.hiddenMstCd,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        sortOrder: this.insItemgrid.data.length == 0 ? 1 : (this.insItemgrid.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
      this.hiddenMstCd = '';
    },
  }
};
</script>
